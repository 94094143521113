.section-inner-right {
	.slide {
		position: relative;
		z-index: 1;
		.slide-inner {
			transition: all 0.6s cubic-bezier(0.4, 0, 1, 1);
			transform: translateY(15px);
			opacity: 0;
		}
		&.slick-active .slide-inner {
			transform: translateY(0px);
			opacity: 1;
		}
	}
}