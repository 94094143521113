#main.noIScroll {
	@media ($laptop) {
		height: auto;
		#wrapper {
			height: auto;
			position: relative;
		}
		.fullpage-section {
			height: auto;
		}
		.fullpage-content {
			padding-bottom: 80px;
			position: relative;
			top: auto;
			left: auto;
			transform: none;
		}
		.section-0 {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.section-0 .fullpage-content {
			padding-top: 120px;
			#intro-content-wrap {
				position: relative;
				.intro-content {
					transition: none;
				}
				.intro-content:last-of-type {
					position: relative;
					top: auto;
					transform: none;
					h2 {
						font-size: 20px;
					}
					.line {
						display: block;
					}
				}
				&.intro-1 {
					.intro-content:last-of-type {
						opacity: 1;
						visibility: visible;
						transform: none;
					}
				}
				&.intro-2 {
					.intro-content:first-of-type {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
		.section-1 {
			padding-bottom: 0px;
		}
		.section-2 {
			.background {
				height: 100%;
				top: auto;
			}
			.section-inner-right {
				padding-bottom: 60px;
				.slider-arrows {
					display: block;
				}
			}
		}
		.position-marker {
			display: none;
		}
	//no fadein
		.fullpage-section {
			&.section-0 {
				#intro-content-wrap {
					h1, .line, h2 {
						opacity: 1;
						visibility: visible;
						transform: translateY(0px);
					}
					.intro-content:last-of-type {
						display: block;
						position: relative;
						opacity: 1;
						visibility: visible;
						.line {
							display: none;
						}
					}
				}
			}
			&.section-1 {
				.section-inner-left {
					h2 {
						opacity: 1;
						visibility: visible;
						transform: translateY(0px);
					}
				}
				.section-inner-right {
					h3, p {
						opacity: 1;
						visibility: visible;
						transform: translateY(0px);
					}
				}
			}
			&.section-2 {
				.section-inner-left, .section-inner-right {
					opacity: 1;
					visibility: visible;
					transform: translateY(0px);
				}
			}
			&.section-3 {
				.section-inner-left {
					h2, h3, .membership-picker {
						opacity: 1;
						visibility: visible;
						transform: translateY(0px);
					}
				}
				.section-inner-right {
					opacity: 1;
					visibility: visible;
					transform: translateY(0px);
				}
			}
		}
	}
	@media ($desktop) {
		.section-0 .fullpage-content {
			#intro-content-wrap {
				.intro-content:last-of-type {
					h2 {
						font-size: 25px;
					}
				}
			}
		}
	}
}