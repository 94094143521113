@font-face {
	font-family: 'freight';
	src: url('../assets/fonts/freight.woff');
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: 'maison';
	src: url('../assets/fonts/maison-light.woff');
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: 'maison';
	src: url('../assets/fonts/maison-bold.woff');
	font-weight: bold;
	font-style: normal;
}

$maison: maison;
$freight: freight;