/* normalize: http://necolas.github.io/normalize.css/ */
// We can include it from npm but keeping it manually
@import "partials/normalize";

@import "partials/fonts";

/* slick slider */
@import "vendors/slick";
@import "vendors/slick-theme";


/* variables */
$yellow: #BD9E1E;
$blue: #041E41;

$mobile: 'min-width: 500px';
$tablet: 'min-width: 760px';
$laptop: 'min-width: 960px';
$desktop: 'min-width: 1300px';

/* mixins */
@mixin font-smoothing($value: on) {
	@if $value == on {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	@else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

/* styles mobile first */
// All scss

/* RETINA */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (min--moz-device-pixel-ratio: 1.5),
	only screen and (min-device-pixel-ratio: 1.5) {
	// retina 2x style
}
@media print {
	// @import "partials/print";
}

html{
	box-sizing: border-box;
}
html *{
	box-sizing: inherit;
}

// svg
.svg-container{
	display: none;
}

/* inhouse */
@import "partials/header";
@import "partials/body";
@import "partials/slider";
@import "partials/fadein";
@import "partials/noIScroll";
@import "partials/otherpages";
@import "partials/footer";

.single-post.admin-bar{
	header{
		top: 32px;
	}
	#content{
		padding-top: 32px;
	}
}
