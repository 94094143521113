header {
	transition: padding 0.3s ease-out,
				height 0.3s ease-out;
	z-index: 10;
	position: fixed;
	top: 0;
	left: 0;
	// background-color: rgba(10,28,53,1);
	height: 56px;
	width: 100%;
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(10,28,53,1);
		position: absolute;
		top: 0;
		left: 0;
		opacity: 1;
	}
	@media ($laptop) {
		height: 80px;
	}
	.header-inner {
		transition: opacity 0.3s ease-out;
		z-index: 0;
		opacity: 0;
		visibility: hidden;
		text-align: center;
		left: 0;
		top: 0;
		background-color: $blue;
		position: absolute;
		height: 100vh;
		width: 100%;
		max-width: 1200px;
		padding: 40px 100px;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
		@media ($laptop) {
			opacity: 1;
			visibility: visible;
			padding: 0 130px;
			text-align: left;
			top: auto;
			background-color: transparent;
			height: 80px;
			left: 50%;
			transform: translateX(-50%);
		}
		@media (min-width: 1000px) {
			padding: 0 150px;
		}
		@media (min-width: 1100px) {
			padding: 0 160px;
		}
		@media ($desktop) {
			padding: 0 100px;
		}
	}
	.header-logo {
		cursor: pointer;
		width: 46px;
		height: 46px;
		display: inline-block;
		margin-bottom: 20px;
		svg {
			height: 100%;
			width: 100%;
		}
		@media ($laptop) {
			margin-bottom: 0;
			float: left;
			width: 50px;
			height: 80px;
		}
	}
	.header-links {
		font-size: 22px;
		font-weight: bold;
		font-family: $maison;
		padding: 0 0 30px;
		margin: 0;
		-webkit-padding-start: 0em;
		-webkit-margin-before: 0em;
		-webkit-margin-after: 0em;
		line-height: 70px;
		padding-bottom: 30px;
		@media ($laptop) {
			font-size: 20px;
			padding-bottom: 0;
			line-height: 80px;
			float: left;
			padding: 0 0 0 90px;
			-webkit-padding-start: 90px;
			-webkit-margin-before: 0em;
			-webkit-margin-after: 0em;
		}
		@media ($desktop) {
			font-size: 22px;
			padding: 0 0 0 120px;
			-webkit-padding-start: 120px;
		}
		li {
			list-style: none;
			display: block;
			@media ($laptop) {
				display: inline-block;
				&:after {
					color: #fff;
					content: ' / ';
					margin: 0 10px;
				}
				&:last-of-type:after {
					content: ' ';
				}
			}
			a {
				color: #fff;
				text-decoration: none;
				transition: color 0.3s ease-out;
				&:hover {
					color: $yellow;
				}
			}
			.active {
				color: $yellow;
			}
		}
	}
	.footer-links {
		letter-spacing: 1.23px;
		margin: 0 auto;
		max-width: 270px;
		padding-top: 30px;
		border-top: 1px solid #fff;
		padding-left: 0;
		-webkit-padding-start: 0em;
		-webkit-margin-before: 0em;
		-webkit-margin-after: 0em;
		color: #fff;
		li {
			list-style: none;
			display: block;
			font-family: $maison;
			font-size: 16px;
			line-height: 50px;
		}
		a {
			transition: color 0.3s ease-out;
			color: #fff;
			text-decoration: none;
			&:hover {
				color: $yellow;
			}
		}
		@media($laptop) {
			display: none;
		}
	}
}

.home {
	.header-logo {
		@media ($laptop) {
			transition: 0.4s ease-out;
		}
	}
}

.home header.fixed {
	@media ($laptop) {
		height: 140px;
		padding: 35px 70px;
		// background-color: rgba(10,28,53,0);
		&:before {
			transition: 0.3s ease-out;
			opacity: 0;
		}
	}
	.header-logo {
		@media ($laptop) {
			width: 70px;
			height: 74px;
		}
		@media ($desktop) {
			width: 80px;
			height: 80px;
		}
	}
	.header-links {
		@media ($laptop) {
			line-height: 1.5em;
			font-size: 25px;
			max-width: 600px;
		}
		@media ($desktop) {
			font-size: 30px;
		}
	}
}

// body.mobile {
	.mobile-hamburger {
		cursor: pointer;
		width: 16px;
		height: 16px;
		position: fixed;
		top: 20px;
		left: 20px;
		@media ($laptop) {
			display: none;
		}
		span {
			position: absolute;
			display: block;
			width: 16px;
			height: 2px;
			background-color: #fff;
			transition: 0.3s ease-out;
		}
		span:nth-of-type(1) {
			top: 0;
			left: 0;
		}
		span:nth-of-type(2) {
			top: 7px;
			left: 0;
		}
		span:nth-of-type(3) {
			top: 14px;
			left: 0;
		}
	}
// }
body.mobile-menu-open {
	.header-inner {
		visibility: visible;
		opacity: 1;
	}
	.mobile-hamburger {
		span:nth-of-type(1) {
			width: 20px;
			transform: rotate(45deg);
			transform-origin: left center;
		}
		span:nth-of-type(2) {
			visibility: hidden;
			opacity: 0;
		}
		span:nth-of-type(3) {
			width: 20px;
			transform: rotate(-45deg);
			transform-origin: left center;
		}
	}
}