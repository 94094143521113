.footer {
	background-color: #f0f0f0;
	border-top: 2px solid #e2e2e2;
	.footer-inner {
		max-width: 1200px;
		margin: 0 auto;
		padding: 60px 30px 30px;
		@media ($mobile) {
			padding: 30px 50px 50px 50px;
		}
		@media (min-width: 600px) {
			padding: 30px 100px 50px 100px;
		}
		@media (min-width: 800px) {
			padding: 30px 150px 50px 150px;
		}
		@media ($laptop) {
			padding: 10px 130px 30px 130px;
		}
		@media (min-width: 1000px) {
			padding: 10px 150px 30px 150px;
		}
		@media (min-width: 1100px) {
			padding: 10px 160px 30px 160px;
		}
		@media ($desktop) {
			padding: 10px 100px 30px 100px;
		}
	}
	.footer-logo {
		margin-bottom: 5px;
		margin-right: 0;
		@media ($laptop) {
			float: right;
			display: inline-block;
		}
		svg {
			transition: fill 0.3s ease-out;
			fill: #cfd0ce;
			vertical-align: middle;
		}
		&:hover svg {
			fill: #a7a7a7;
		}
	}
	.site-credit {
		cursor: pointer;
		display: block;
		font-family: $maison;
		color: #cfd0ce;
		font-size: 12px;
		font-weight: bold;
		line-height: 2em;
		@media ($laptop) {
			font-size: 13px;
		}
		a {
			display: inline-block;
		}
	}
	a {
		transition: 0.3s ease-out;
		display: block;
		text-decoration: none;
		font-family: $maison;
		color: #cfd0ce;
		font-size: 12px;
		font-weight: bold;
		line-height: 2em;
		@media ($laptop) {
			font-size: 13px;
			display: inline-block;
			margin-right: 30px;
			margin-bottom: 5px;
		}
		&:hover {
			color: #a7a7a7;
		}
	}
}
.mobile-apply-button-visible {
	.footer .footer-inner {
		padding-bottom: 95px;
		@media ($mobile) {
			padding-bottom: 90px;
		}
		@media ($laptop) {
			padding-bottom: 20px;
		}
	}
}