.general-text,
.apply-intro {
	margin: 0 auto;
	padding-top: 60px;
	padding-right: 30px;
	padding-left: 30px;
	@media ($mobile) {
		padding-left: 50px;
		padding-right: 50px;
	}
	@media (min-width: 600px) {
		padding-left: 100px;
		padding-right: 100px;
	}
	@media (min-width: 800px) {
		padding-left: 150px;
		padding-right: 150px;
	}
	@media ($laptop) {
		width: 90%;
		max-width: 600px;
		padding-top: 80px;
		padding-left: 0px;
		padding-right: 0px;
	}
}

.apply-intro {
	p {
		max-width: none;
	}
}

.application-form {
	p {
		max-width: none;
	}
	padding: 0;
	margin: 0 auto;
	max-width: 1080px;
	@media (max-width: 499px) {
		padding-right: 10px;
		padding-left: 10px;
	}
}

.general-text,
.apply-intro {
	h3 {
		margin-top: 20px;
		color: $yellow;
		font-family: $maison;
		font-size: 16px;
		@media ($laptop) {
			margin-top: 80px;
			font-size: 25px;
		}
		&:after {
			content: '';
			display: block;
			width: 22px;
			height: 1px;
			background-color: $blue;
			margin-top: 17px;
			@media ($laptop) {
				margin-top: 30px;
			}
		}
	}
}

.general-text {
	font-family: $maison;
	font-size: 14px;
	line-height: 1.6em;
	min-height: calc(100vh - 300px);
	@media ($laptop) {
		min-height: calc(100vh - 130px);
		font-size: 16px;
	}
	a {
		color: $yellow;
	}
}

.apply-intro {
	h2 {
		font-family: $freight;
		line-height: 1.5em;
		color: $blue;
		letter-spacing: 0.5px;
		font-weight: normal;
		margin-bottom: 0.5em;
		font-size: 30px;
		max-width: 300px;
		@media ($laptop) {
			max-width: 540px;
			line-height: 1em;
			font-size: 70px;
		}
		.yellow {
			font-weight: normal;
			color: $yellow;
		}
		&:after {
			@media ($laptop) {
				content: '';
				display: block;
				width: 22px;
				height: 1px;
				background-color: $yellow;
				margin-top: 30px;
			}
		}
	}
	p {
		font-family: $maison;
		line-height: 1.6em;
		font-size: 14px;
		@media ($tablet) {
			font-size: 16px;
		}
		@media ($laptop) {
			letter-spacing: 0.35px;
			font-size: 17px;
		}
	}
}
.application-form {
	margin: 0px auto 100px;
	iframe {
		border: none;
		.fsBody {
			background-color: none;
		}
	}
}