html, body {
	margin: 0;
	color: $blue;
	@include font-smoothing(on);
	h2, h3, p {
		max-width: 550px;
	}
	h1 .yellow, h2 .yellow, h3 .yellow, p .yellow {
		color: $yellow;
	}
	h1 .blue, h2 .blue, h3 .blue, p .blue {
		color: $blue;
	}
	p strong {
		@include font-smoothing(on);
	}
}
#wrapper {
	overflow: hidden;
	@media ($laptop) {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		overflow: hidden;
	}
}
body.disable-scrolling {
	@media ($laptop) {
		#wrapper {
			position: fixed;
		}
		.footer {
			display: none;
		}
	}
}
.home-main {
	@media ($laptop) {
		height: 100vh;
	}
}
.fullpage {
	width: 100%;
	@media (max-width: 959px) {
		transform: none !important;
	}
	&-section {
		width: 100%;
		@media ($laptop) {
			display: table;
			height: 100vh;
			overflow: hidden;
		}

			position: relative;
	}
	&-content {
		// text-align: center;
		// display: table-cell;
		// vertical-align: middle;

		max-width: 1200px;
		width: 100%;
		margin: 0 auto;
		padding: 50px 30px 0 30px;
		@media ($mobile) {
			padding: 50px 50px 0 50px;
		}
		@media (min-width: 600px) {
			padding: 50px 100px 0 100px;
		}
		@media (min-width: 800px) {
			padding: 50px 150px 0 150px;
		}
		@media ($laptop) {
			padding: 80px 130px 0 130px;
		}
		@media (min-width: 1000px) {
			padding: 80px 150px 0 150px;
		}
		@media (min-width: 1100px) {
			padding: 80px 160px 0 160px;
		}
		@media ($desktop) {
			padding: 80px 100px 0 100px;
		}
	}
	.section-inner-left,
	.section-inner-right {
		position: relative;
	}
	.section-inner-left{
		@media ($laptop) {
			width: 35%;
			float: left;
		}
	}
	.section-inner-right {
		@media ($laptop) {
			width: 65%;
			float: right;
			padding-left: 6%;
			padding-right: 1%;
		}
		@media ($desktop) {
			padding-left: 10%;
		}
	}
	.section-0 {
		min-height: 100vh;
		background-color: $blue;
		margin-bottom: 20px;
		background-image: url('../assets/images/IHS18_Website_Creative-FinalImages-Plate.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		@media ($laptop) {
			display: block;
			background-color: $blue;
			margin-bottom: 0;
		}
		.fullpage-content {
			padding-top: 80px;
			padding-bottom: 50px;
			@media ($laptop) {
				padding-bottom: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		#intro-content-wrap {
			.intro-content:last-of-type .line {
				display: none;
			}
		}
		@media ($laptop) {
			#intro-content-wrap {
				position: relative;
				.intro-content {
					transition: 0.6s ease-out;
				}
				.intro-content:last-of-type {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					h2 {
						font-size: 32px;
					}
					.line {
						display: block;
					}
				}
				&.intro-1 {
					.intro-content:last-of-type {
						opacity: 0;
						visibility: hidden;
						transform: translateY(-45%);
					}
				}
				&.intro-2 {
					.intro-content:first-of-type {
						opacity: 0;
						visibility: hidden;
					}
				}
			}
		}
		h1 {
			color: #fff;
			font-size: 60px;
			font-weight: normal;
			font-family: $freight;
			margin: 0;
			line-height: 1em;
			.yellow {
				font-weight: normal;
				color: $yellow;
			}
			@media ($mobile) {
				font-size: 80px;
			}
			@media ($tablet) {
				font-size: 100px;
			}
			@media ($laptop) {
				font-size: 125px;
			}
			@media ($desktop) {
				font-size: 136px;
			}
		}
		.line {
			width: 22px;
			height: 1px;
			background-color: #fff;
			margin: 20px 0 50px;
		}
		h2 {
			font-weight: bold;
			color: #fff;
			font-family: $maison;
			font-size: 14px;
			line-height: 1.5em;
			@media ($mobile) {
				font-size: 20px;
			}
			@media ($tablet) {
				font-size: 22px;
			}
			@media ($laptop) {
				font-size: 20px;
			}
			@media ($desktop) {
				font-size: 25px;
			}
		}
	}
	.section-1 {
		background-color: #fff;
		padding-bottom: 120px;
		&:before {
			content: '';
			display: block;
			position: absolute;
			height: 100%;
			width: 100%;
			max-width: 1200px;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			background-image: url('../assets/images/IHS18_Website_Creative-FinalImages-Pepper.jpg');
			background-repeat: no-repeat;
			background-size: 80% auto;
			background-position: 100% 100%;
			opacity: 0.3;
			@media ($mobile) {
				background-size: 60% auto;
			}
			@media ($tablet) {
				background-size: contain;
				opacity: 0.7;
			}
			@media ($laptop) {
				background-size: auto;
				background-position: 0% 100%;
			}
		}
		.fullpage-content {
			position: relative;
			@media ($laptop) {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			&:after {
				content: '';
				display: block;
				clear: both;
			}
		}
		h2 {
			color: $yellow;
			font-family: $maison;
			font-size: 16px;
			@media ($laptop) {
				font-size: 25px;
			}
			&:after {
				content: '';
				display: block;
				width: 22px;
				height: 1px;
				background-color: $blue;
				margin-top: 17px;
				@media ($laptop) {
					margin-top: 30px;
				}
			}
		}
		h3 {
			letter-spacing: 0.25px;
			font-size: 14px;
			font-family: $maison;
			color: $blue;
			font-weight: normal;
			margin-bottom: 40px;
			line-height: 1.6em;
			max-width: 390px;
			@media ($tablet) {
				font-size: 16px;
			}
			@media ($laptop) {
				@include font-smoothing(off);
				font-size: 17px;
				margin-bottom: 55px;
				letter-spacing: 0.35px;
			}
			@media ($desktop) {
				margin-bottom: 70px;
			}
		}
		p {
			font-size: 30px;
			letter-spacing: 0.3px;
			font-family: $freight;
			.yellow {
				color: $yellow;
				font-weight: normal;
			}
			@media ($mobile) {
				font-size: 34px;
			}
			@media ($laptop) {
				font-size: 36px;
			}
		}
	}
	.section-2 {
		background: linear-gradient(180deg, #fff 50%, #f0f0f0 50%);
		@media ($laptop) {
			background: linear-gradient(180deg, #fff 50%, #f0f0f0 50%);
			.background {
				top: 105px;
				left: 50%;
				transform: translateX(-50%);
				position: absolute;
				width: calc(100% - 50px);
				height: calc(100vh - 130px);
				background-color: #0b1c36;
				&:before {
					content: '';
					display: block;
					width: 50%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					background-color: #b3972c;
				}
				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					background-image: url('../assets/images/IHS18_Website_Creative-FinalImages-Pairing-img900h.jpg');
					background-size: auto 100%;
					background-repeat: no-repeat;
					background-position: 50% 50%;
				}
			}
		}
		@media ($desktop) {
			.background {
				top: 130px;
				left: 50%;
				transform: translateX(-50%);
				position: absolute;
				width: calc(100% - 100px);
				height: calc(100vh - 180px);
			}
		}
		.fullpage-content {
			padding-bottom: 40px;
			position: relative;
			@media ($laptop) {
				padding-bottom: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			&:after {
				content: '';
				display: block;
				clear: both;
			}
		}
		.section-inner-right {
			padding-left: 10%;
			padding-right: 10%;
			padding-top: 40px;
			padding-bottom: 60px;
			background-image: url('../assets/images/mobilesplitbg.jpg');
			background-size: cover;
			background-position: 50% 50%;
			@media ($laptop) {
				margin-bottom: 0;
				padding-top: 0px;
				padding-bottom: 0px;
				background: none;
			}
		}
		h2 {
			margin-top: 0;
			color: $yellow;
			font-family: $maison;
			font-size: 16px;
			@media ($laptop) {
				font-size: 25px;
			}
			&:after {
				content: '';
				display: block;
				width: 22px;
				height: 1px;
				background-color: $blue;
				margin-top: 17px;
				@media ($laptop) {
					margin-top: 30px;
				}
			}
			@media ($laptop) {
				color: $blue;
			}
		}
		h3 {
			letter-spacing: 0.25px;
			font-family: $maison;
			color: $blue;
			font-weight: normal;
			margin-bottom: 40px;
			line-height: 1.6em;
			font-size: 14px;
			@media ($tablet) {
				font-size: 16px;
			}
			@media ($laptop) {
				@include font-smoothing(off);
				font-size: 17px;
				letter-spacing: 0.35px;
				color: #fff;
				margin-bottom: 100px;
			}
		}
		.slider {
			max-width: 500px;
		}
		.slide-number {
			color: #fff;
			font-family: $maison;
			font-size: 20px;
			font-weight: bold;
			@media ($laptop) {
				font-size: 22px;
			}
			@media ($laptop) {
				font-size: 25px;
			}
			&:after {
				content: '';
				display: block;
				width: 22px;
				height: 1px;
				background-color: #fff;
				margin-top: 20px;
				@media ($laptop) {
					margin-top: 26px;
				}
				@media ($laptop) {
					margin-top: 30px;
				}
			}
		}
		.slider-arrows {
			width: 80%;
			position: absolute;
			bottom: 30px;
			.slider-arrow-left {
				float: left;
			}
			.slider-arrow-right {
				float: right;
			}
			@media ($laptop) {
				display: none;
			}
		}
		blockquote {
			// -webkit-margin-before: 1em;
			// -webkit-margin-after: 1em;
			margin-left: 0px;
			margin-right: 0px;
			-webkit-margin-start: 0px;
			-webkit-margin-end: 0px;
			p {
				color: #fff;
				font-size: 38px;
				line-height: 1em;
				letter-spacing: 0.36px;
				margin-top: 0.5em;
				margin-bottom: 0.5em;
				-webkit-margin-before: 0.5em;
				-webkit-margin-after: 0.5em;
				@media ($mobile) {
					font-size: 50px;
				}
				@media ($laptop) {
					font-size: 58px;
				}
				@media ($desktop) {
					font-size: 70px;
				}
			}
			footer {
				color: #fff;
				font-family: $maison;
				font-size: 14px;
				line-height: 1.5em;
				font-weight: normal;
				@media ($tablet) {
					font-size: 16px;
				}
				@media ($laptop) {
					@include font-smoothing(off);
					font-size: 17px;
					letter-spacing: 0.35px;
				}
				strong {
					@media ($laptop) {
						letter-spacing: 0.4px;
					}
				}
			}
		}
	}
	.section-3 {
		background-color: #f0f0f0;
		padding-bottom: 50px;
		@media ($laptop) {
			padding-bottom: 0;
		}
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url('../assets/images/IHS18_Website_Creative-FinalImages-Table-graybground.jpg');
			background-size: 150%;
			background-repeat: no-repeat;
			background-position: 100% 100%;
			opacity: 0.4;
			@media ($tablet) {
				background-size: contain;
			}
			@media ($laptop) {
				background-size: cover;
				opacity: 0.4;
				background-position: 50% 50%;
			}
		}
		.fullpage-content {
			position: relative;
			@media ($laptop) {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				display: flex;
			}
			&:after {
				content: '';
				display: block;
				clear: both;
			}
		}
		.section-inner-right {
			border-bottom: 1px solid #CFD0CE;
		}
		@media ($laptop) {
			.section-inner-left {
				width: 35%;
				float: none;
			}
			.section-inner-right {
				border-bottom: none;
				width: 65%;
				float: none;
				margin-top: 94px;
			}
		}
		h2 {
			margin-top: 0;
			color: $yellow;
			font-family: $maison;
			font-size: 16px;
			@media ($laptop) {
				font-size: 25px;
				color: $yellow;
			}
			&:after {
				content: '';
				display: block;
				width: 22px;
				height: 1px;
				background-color: $blue;
				margin-top: 17px;
				@media ($laptop) {
					margin-top: 30px;
				}
			}
		}
		h3 {
			font-size: 36px;
			letter-spacing: 0.25px;
			font-family: $freight;
			color: $blue;
			font-weight: normal;
			margin-bottom: 30px;
			line-height: 1.1em;
			@media ($laptop) {
				color: $blue;
				margin-top: 10px;
				margin-bottom: 0px;
			}
		}
		.section-inner-left-bottom {
			display: none;
			@media ($laptop) {
				display: block;
			}
			float: right;
			width: 226px;
		}
		.membership-picker {
			margin: 18px 0;
			float: left;
			list-style: none;
			padding-left: 0;
			width: 100%;
			li {
				border-top: 1px solid #cfd0ce;
				cursor: pointer;
				font-family: $maison;
				font-size: 16px;
				line-height: 1.2em;
				padding: 12px 0;
				font-weight: bold;
				transition: 0.3s ease-out;
				&:hover {
					color: $yellow;
				}
			}
			li:last-of-type {
				border-bottom: 1px solid #cfd0ce;
			}
			li.active {
				color: $yellow;
			}
		}
		.apply-button {
			width: 180px;
			transition: 0.3s ease-out;
			height: 50px;
			background-color: $blue;
			float: left;
			color: $yellow;
			font-family: $maison;
			font-weight: bold;
			text-decoration: none;
			line-height: 50px;
			text-align: center;
			&:hover {
				background-color: $yellow;
				color: $blue;
			}
		}
		.js-membership-content {
			font-family: $maison;
			font-size: 14px;
			line-height: 1.6em;
			display: none;
			margin-bottom: 1em;
			@media ($tablet) {
				font-size: 16px;
			}
			@media ($laptop) {
				margin-bottom: 0;
				@include font-smoothing(off);
				font-size: 17px;
				letter-spacing: 0.35px;
				display: none;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				padding: 0 5% 0 10%;
				overflow-y: auto;
			}
			@media ($desktop) {
				padding: 0 10% 0 10%;
			}
			p:first-of-type { 
				margin-top: 0;
			}
			p:last-of-type {
				margin-bottom: 0;
			}
			&.short {
				@media ($laptop) {
					p:first-of-type { 
						margin-top: 75px;
					}
				}
			}
		}
		h4.js-membership-picker-mobile {
			border-top: 1px solid #CFD0CE;
			cursor: pointer;
			margin: 0;
			padding: 25px 0;
			position: relative;
			font-family: $maison;
			font-weight: bold;
			font-size: 19px;
			&:after {
				width: 18px;
				height: 2px;
				content: '';
				display: block;
				background-color: $yellow;
				position: absolute;
				top: 33px;
				right: 0;
			}
			&:before {
				width: 2px;
				height: 18px;
				content: '';
				display: block;
				background-color: $yellow;
				position: absolute;
				right: 8px;
				top: 25px;
				opacity: 1;
				transition: 0.3s ease-out;
			}
			@media ($laptop) {
				display: none;
			}
		}
		h4.js-membership-picker-mobile.active {
			border-bottom: 0px;
			color: $yellow;
			&:before {
				opacity: 0;
			}
		}
		.js-membership-content.active {
			display: block;
			// @media ($laptop) {
			// 	visibility: visible;
			// 	opacity: 1;
			// }
		}
	}
}

.position-marker {
	display: none;
	@media ($laptop) {
		display: block
	}
	color: #fff;
	position: absolute;
	right: 10%;
	top: 50%;
	transform: translateY(-50%);
	font-family: $maison;
	font-size: 13px;
	letter-spacing: 0.9px;
	.position-marker-line {
		transition: 0.3s ease-out;
		height: 80px;
		width: 1px;
		background-color: #fff;
		position: relative;
	}
	.position-marker-o {
		transition: 0.5s ease-out;
		position: absolute;
		top: 0;
		left: 0;
		transform: translateX(-50%);
	}
	.position-marker-title {
		transition: 0.3s ease-out;
		width: 1px;
		height: 14px;
		white-space: nowrap;
		transform: rotate(90deg);
		transform-origin: left;
		margin-top: 10px;
	}
	.section-number {
		font-weight: bold;
	}
	.section-title {
		text-transform: capitalize;
		@include font-smoothing(off);
	}
}
.position-marker[data-position="0"] {
	color: $yellow;
	.position-marker-line {
		background-color: #fff;
	}
	.position-marker-o {
		fill: #fff;
	}
}
.position-marker[data-position="1"],
.position-marker[data-position="3"] {
	color: $blue;
	.position-marker-line {
		background-color: $blue;
	}
	.position-marker-o {
		fill: $blue;
	}
}
.position-marker[data-position="2"] {
	color: #fff;
	.position-marker-line {
		background-color: #fff;
	}
	.position-marker-o {
		fill: #fff;
	}
}

#mobile-apply-button {
	transition: 0.3s ease-out;
	width: 100%;
	bottom: 0;
	position: fixed;
	font-family: "Freight Big Medium";
	text-align: center;
	min-height: 65px;
	.down-arrow {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	a {
		visibility: hidden;
		opacity: 0;
		text-decoration: none;
		color: $yellow;
		font-size: 25px;
		display: block;
		line-height: 50px;
		&:after {
			content: '';
			display: block;
			width: 20px;
			height: 1px;
			background-color: #fff;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	&.visible {
		background-color: $blue;
		.down-arrow {
			opacity: 0;
			visibility: hidden;
		}
		a {
			visibility: visible;
			opacity: 1;
		}
	}
	@media ($laptop) {
		display: none;
	}
}