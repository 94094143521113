@media ($laptop) {
	.fullpage-section {
		&.section-0 {
			#intro-content-wrap {
				h1 {
					transition: 600ms ease-out;
					transition-delay: 300ms;
					opacity: 0;
					visibility: hidden;
					transform: translateY(15px);
				}
				.line, h2 {
					transition: 600ms ease-out;
					transition-delay: 600ms;
					opacity: 0;
					visibility: hidden;
					transform: translateY(15px);
				}
			}
			&.js-active-section {
				#intro-content-wrap {
					h1, .line, h2 {
						opacity: 1;
						visibility: visible;
						transform: translateY(0px);
					}
				}
			}
		}
		&.section-1 {
			.section-inner-left {
				h2 {
					transition: 600ms ease-out;
					transition-delay: 300ms;
					opacity: 0;
					visibility: hidden;
					transform: translateY(15px);
				}
			}
			.section-inner-right {
				h3 {
					transition: 600ms ease-out;
					transition-delay: 300ms;
					opacity: 0;
					visibility: hidden;
					transform: translateY(15px);
				}
				p {
					transition: 600ms ease-out;
					transition-delay: 600ms;
					opacity: 0;
					visibility: hidden;
					transform: translateY(15px);
				}
			}
			&.js-active-section {
				.section-inner-left {
					h2 {
						opacity: 1;
						visibility: visible;
						transform: translateY(0px);
					}
				}
				.section-inner-right {
					h3, p {
						opacity: 1;
						visibility: visible;
						transform: translateY(0px);
					}
				}
			}
		}
		&.section-2 {
			.section-inner-left {
				transition: 600ms ease-out;
				transition-delay: 300ms;
				opacity: 0;
				visibility: hidden;
				transform: translateY(15px);
			}
			.section-inner-right {
				transition: 600ms ease-out;
				transition-delay: 600ms;
				opacity: 0;
				visibility: hidden;
				transform: translateY(15px);
			}
			&.js-active-section {
				.section-inner-left, .section-inner-right {
					opacity: 1;
					visibility: visible;
					transform: translateY(0px);
				}
			}
		}
		&.section-3 {
			.section-inner-left {
				h2, h3 {
					transition: 600ms ease-out;
					transition-delay: 300ms;
					opacity: 0;
					visibility: hidden;
					transform: translateY(15px);
				}
				.section-inner-left-bottom {
					transition: 600ms ease-out;
					transition-delay: 600ms;
					opacity: 0;
					visibility: hidden;
					transform: translateY(15px);
				}
			}
			.section-inner-right {
				transition: 600ms ease-out;
				transition-delay: 900ms;
				opacity: 0;
				visibility: hidden;
				transform: translateY(15px);
			}
			&.js-active-section {
				.section-inner-left {
					h2, h3, .section-inner-left-bottom {
						opacity: 1;
						visibility: visible;
						transform: translateY(0px);
					}
				}
				.section-inner-right {
					opacity: 1;
					visibility: visible;
					transform: translateY(0px);
				}
			}
		}
	}
}